import AppButton from '@theme/components/atom/AppButton'
import AppTitle from '@theme/components/atom/AppTitle'
import Modal from '@theme/components/utils/Modal'

export default {
  props: {
    entryModalId: {
      type: String,
      default: 'EntryModal',
    },
  },
  components: {
    AppButton,
    AppTitle,
    Modal,
  },
  computed: {
    alreadyClosed() {
      return this.$store.getters['globals/isEntryModalClosed'](this.entryModalId)
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if (!this.alreadyClosed) {
        this.$refs.modal.show()
      }
    },
    hide() {
      this.$emit('hide')
      this.$store.commit('globals/CLOSE_ENTRY_MODAL', this.entryModalId)
    },
  },
  watch: {
    alreadyClosed: function(val) {
      this.$nextTick(() => {
        this.init()
      })
    },
  },
}
