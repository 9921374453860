<template>
  <div>
    <client-only>
      <notifications
        :duration="5000"
        :max="4"
        position="top center"
        classes="notification" />
      <component
        :is="entryModalComponent"
        :entry-modal-id="entryModalId"
        @hide="entryModalHide">
        <div
          v-if="entryModalId === 'EntryModalCampaign'"
          slot="content">
          <EntryModalCampaign/>
        </div>
      </component>
      <component :is="adminPanelComponent"/>
      <portal-target
        name="modal"
        multiple/>
      <portal-target
        name="fullscreenPresenter"/>
    </client-only>
  </div>
</template>

<script>
import Component from '~/sites/shop/layouts/_utils'
import EntryModalCampaign from '@theme/components/shop/EntryModalCampaign'

export default {
  components: {
    EntryModalCampaign,
  },
  extends: Component,
  data() {
    return {
      entryModalId: 'EntryModal',
    }
  },
  mounted() {
    if (this.$store.getters['globals/isEntryModalClosed']('EntryModal')) {
      this.entryModalHide()
    }
  },
  methods: {
    entryModalHide() {
      if (this.$store.state.globals.settings.integrations.welcome_campaign === '1') {
        setTimeout(() => {
          this.entryModalId = 'EntryModalCampaign'
        }, 5000)
      }
    },
  },
}
</script>
