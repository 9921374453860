import { errorFromResponse, notifications } from '@/app/notifications'
import AppButton from '@theme/components/atom/AppButton'
import AppInput from '@theme/components/atom/AppInput'
import AppTitle from '@theme/components/atom/AppTitle'
import EntryModal from '@theme/components/shop/EntryModal'
import InjectValidator from '~/app/InjectValidator'
import LoadingMixin from '~/mixins/LoadingMixin'
import Modal from '@theme/components/utils/Modal'
import ProvideValidator from '@/app/ProvideValidator'
import eventBus from '@/events/eventBus'
import eventDefinitions from '@/events/eventDefinitions'

export default {
  mixins: [LoadingMixin, ProvideValidator],
  components: {
    AppButton,
    AppInput,
    AppTitle,
    Modal,
    EntryModal,
  },
  data() {
    return {
      email: '',
      popupIndex: 1,
    }
  },
  methods: {
    async submit() {
      if (!(await this.$validator.validate())) {
        notifications.danger(this.$validator.errors.items[0].msg)
        return
      }
      this.startLoading()
      const response = await this.$store.dispatch('user/SUBSCRIBE_NEWSLETTER', {
        callbackUrl: window.location.origin + this.localePath({ name: 'user-activate' }),
        email: this.email,
        source: 'welcome',
      })
      if (response) {
        if (response.data && response.data.errors && response.data.errors[0] && response.status !== 401) {
          errorFromResponse(response)
        } else {
          eventBus.$emit(eventDefinitions.NEWSLETTER.SUBSCRIBED, {
            email: this.email,
            source: 'welcome',
          })
          this.email = ''
          this.popupIndex += 1
        }
      }
      this.stopLoading()
    },
  },
}
