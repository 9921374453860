import { merge } from 'lodash'
import contentSettings from '~/sites/virtshop/content.settings'

const overloadSettings = {
  events: {
    targito: {
      domain: 'targito.eros.sk',
      origin: 'eros_sk',
    },
  },
  foxentry: {
    id: 'Hgc8CeOq2R',
    routes: ['RouteCheckoutInfo', 'RouteRegister'],
  },
  quarticon: {
    enable: true,
  },
  vivTracking: {
    cid: 1561517,
    type: 422695,
  },
}

export default merge(contentSettings, overloadSettings)
