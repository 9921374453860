import LayoutBase from '@/themes/base/components/shop/LayoutBase'

export default {
  extends: LayoutBase,
  async mounted() {
    await this.$nextTick()

    const script = document.createElement('script')
    script.id = 'pap_x2s6df8d'
    script.async = true
    script.src = '//login.dognet.sk/scripts/fj27g82d'
    script.onload = script.onreadystatechange = function() {
      const rs = this.readyState
      if (rs && rs != 'complete' && rs != 'loaded') return
      PostAffTracker.setAccountId('bb0daaa4')
      try {
        const CampaignID = 'ca9fe780'
        PostAffTracker.track()
      } catch (e) {
        console.error(e)
      }
    }
    const placeholder = document.getElementById('papPlaceholder')
    placeholder.parentNode.insertBefore(script, placeholder)
    placeholder.parentNode.removeChild(placeholder)
  },
}
