import { actions, mutations, state as parentState } from '~/sites/virtshop/store/quarticon'
import { merge } from 'lodash'

const overloadState = {
  apiKey: 'rIhBSyPx6u7OWRZqXZmaZ7nu26gbmk0m2d7e881r',
  customer: 'bc36081bb0bccf3d',
  placements: {
    category__forYou: '2p674',
    addToCart__crossSell: '2p6fc',
    checkout__cartItems: '2p6eb',
    checkout__emptyCart: '2vb42',
    homepage__forYou: '2p630',
    notFound__forYou: '2p6mj',
    productDetail__related: '2p674',
    productDetail__crossSell: '2p6b8',
    internalError__forYou: '2p6li',
  },
}

const state = () => ({
  ...merge(parentState(), overloadState),
})

export { actions, mutations, state }
